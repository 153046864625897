const upperCaseRegex = /^[A-Z]$/;
const lowerCaseRegex = /^[a-z]$/;
const numberRegex = /^[0-9]$/;
const symbolRegex = /^[-#!$@%^&*()_+|~=`{}[\]:";'<>?,./ ]$/;

interface IsStrongPasswordOptions {
  minLength?: number;
  minLowercase?: number;
  minUppercase?: number;
  minNumbers?: number;
  minSymbols?: number;
  returnScore?: boolean;
  pointsPerUnique?: number;
  pointsPerRepeat?: number;
  pointsForContainingLower?: number;
  pointsForContainingUpper?: number;
  pointsForContainingNumber?: number;
  pointsForContainingSymbol?: number;
}

function countChars(str: string) {
  const result = {};
  Array.from(str).forEach((char) => {
    const curVal = result[char];
    if (curVal) {
      result[char] += 1;
    } else {
      result[char] = 1;
    }
  });
  return result;
}

interface Analysis {
  length: number;
  uniqueChars: number;
  uppercaseCount: number;
  lowercaseCount: number;
  numberCount: number;
  symbolCount: number;
}

/* Return information about a password */
function analyzePassword(password: string): Analysis {
  const charMap = countChars(password);
  const analysis = {
    length: password.length,
    uniqueChars: Object.keys(charMap).length,
    uppercaseCount: 0,
    lowercaseCount: 0,
    numberCount: 0,
    symbolCount: 0,
  };
  Object.keys(charMap).forEach((char) => {
    /* istanbul ignore else */
    if (upperCaseRegex.test(char)) {
      analysis.uppercaseCount += charMap[char];
    } else if (lowerCaseRegex.test(char)) {
      analysis.lowercaseCount += charMap[char];
    } else if (numberRegex.test(char)) {
      analysis.numberCount += charMap[char];
    } else if (symbolRegex.test(char)) {
      analysis.symbolCount += charMap[char];
    }
  });
  return analysis;
}

function scorePassword(
  analysis: Analysis,
  scoringOptions: Required<
    Pick<
      IsStrongPasswordOptions,
      | 'pointsPerUnique'
      | 'pointsPerRepeat'
      | 'pointsForContainingLower'
      | 'pointsForContainingUpper'
      | 'pointsForContainingNumber'
      | 'pointsForContainingSymbol'
    >
  >,
): number {
  let points = 0;
  points += analysis.uniqueChars * scoringOptions.pointsPerUnique;
  points += (analysis.length - analysis.uniqueChars) * scoringOptions.pointsPerRepeat;
  if (analysis.lowercaseCount > 0) {
    points += scoringOptions.pointsForContainingLower;
  }
  if (analysis.uppercaseCount > 0) {
    points += scoringOptions.pointsForContainingUpper;
  }
  if (analysis.numberCount > 0) {
    points += scoringOptions.pointsForContainingNumber;
  }
  if (analysis.symbolCount > 0) {
    points += scoringOptions.pointsForContainingSymbol;
  }
  return points;
}

export default function isStrongPassword(
  str: string | null | undefined,
  options?: IsStrongPasswordOptions,
  returnScore?: false,
): boolean;
export default function isStrongPassword(
  str: string | null | undefined,
  options: IsStrongPasswordOptions,
  returnScore: true,
): number;
export default function isStrongPassword(
  str: string | null | undefined,
  {
    minLength = 8,
    minLowercase = 1,
    minUppercase = 1,
    minNumbers = 1,
    minSymbols = 0,
    pointsPerUnique = 1,
    pointsPerRepeat = 0.5,
    pointsForContainingLower = 10,
    pointsForContainingUpper = 10,
    pointsForContainingNumber = 10,
    pointsForContainingSymbol = 10,
  }: IsStrongPasswordOptions = {},
  returnScore = false,
) {
  if (!str) {
    return false;
  }

  const analysis = analyzePassword(str);
  if (returnScore) {
    return scorePassword(analysis, {
      pointsPerUnique,
      pointsPerRepeat,
      pointsForContainingUpper,
      pointsForContainingLower,
      pointsForContainingNumber,
      pointsForContainingSymbol,
    });
  }
  return (
    analysis.length >= minLength &&
    analysis.lowercaseCount >= minLowercase &&
    analysis.uppercaseCount >= minUppercase &&
    analysis.numberCount >= minNumbers &&
    analysis.symbolCount >= minSymbols
  );
}
